import Vue from 'vue'
import Vuex from 'vuex'
import { systemStore } from 'core/store/index'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    moduleWidget: null,
    noTabWidget: false,
    showBackButton: false,
    elementsWithOverflow: 0,
    searchFlightConfig: null,
    bookingData: null,
    deviceType: null,
    dates: null,
    roundtripFromMulticity: false
  },
  getters: {
    getModuleWidget: state => {
      return state.moduleWidget
    },
    getNoTabWidget: state => {
      return state.noTabWidget
    },
    getSearchFlightConfig: state => {
      return state.searchFlightConfig
    },
    getBookingData: state => {
      return state.bookingData
    },
    displayTarget: state => {
      return state.bookingData.display_logics.find(x => x.device_type.toUpperCase() === state.deviceType)
    },
    getDates: state => {
      return state.dates
    },
    getRoundtripFromMulticity: state => {
      return state.roundtripFromMulticity
    }
  },
  mutations: {
    setModuleWidget (state, module) {
      state.moduleWidget = module
    },
    setNoTabWidget (state, value) {
      state.noTabWidget = value
    },
    setshowBackButton (state, config) {
      state.showBackButton = config
    },
    setSearchFlightConfig (state, config) {
      state.searchFlightConfig = config
    },
    setBookingData (state, bookingData) {
      state.bookingData = bookingData
    },
    setDeviceType (state, deviceType) {
      state.deviceType = deviceType
    },
    setDates (state, dates) {
      state.dates = dates
    },
    setRoundtripFromMulticity (state, roundtripFromMulticity) {
      state.roundtripFromMulticity = roundtripFromMulticity
    }
  },
  actions: {
    setInitWidget ({ commit }) {
      commit('setModuleWidget', null)
      commit('setNoTabWidget', false)
    },
    notifyOverflowToParent ({ state }, isOverflow) {
      state.elementsWithOverflow += isOverflow ? 1 : -1
      window.parent.postMessage({ kiuElementsOverflow: state.elementsWithOverflow }, '*')
    }
  },
  modules: {
    systemStore
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
