<template>
  <alert-component
    :buttons_enable="buttons_enable"
    @close-alert-component="closeDiv"
    cookie="false"
  >
    <template v-slot:container>
      <div  class="alertComponent__message">
        <ul>
          <li
            v-for="(item, index) in messagesDetails"
            :key="index"
          >
            <div class="message-html">
              <i class="fas fa-exclamation-triangle mr-2"></i>
              <span v-html-safe="$t('message_' + item.name)"></span>
              <span v-if="item.show_datetime"> - {{ $moment(item.datetime).utcOffset(item.datetime).format('DD/MM/YYYY HH:mm') }}</span>
              <span v-if="item.link"> - <a :href="item.link" target="_blank"> {{ item.link }} </a></span>
            </div>
          </li>
        </ul>
      </div>
     </template>
  </alert-component>
</template>

<script>
import alertComponent from './AlertComponent'
export default {
  name: 'temporaryMessage',
  components: {
    alertComponent
  },
  props: ['details', 'buttons_enable'],
  methods: {
    closeDiv () {
      this.$emit('close-alert-component')
    }
  },
  computed: {
    messagesDetails: function () {
      return this.details.filter(x => x.enable === true)
    }
  }
}
</script>

<style scoped lang="sass">
.alertComponent
  right: 0
  margin: auto
ul
  list-style-type: none
  padding-left: 0
  margin-bottom: 0
li
  margin-bottom: .5rem
.alertComponent__label
  p
    margin: 0 0 0 1rem
.alertComponent__message
  display: flex
  flex-direction: row
  align-items: center
  padding: 0
.alertComponent__content
  margin-bottom: 0
.message-html
  font-size: var(--font-size-sm)
  display: flex
  align-items: center
  i
    font-size: var(--font-size-sm)
  span
    margin-left: 6px
</style>
<style lang="sass">
.message-html
  p
    margin: 0
</style>
