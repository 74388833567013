<template>
  <alert-component :buttons_enable="buttons_enable" @close-alert-component="closeDiv" :cookie=true>
        <template v-slot:container>
          <div  class="alertComponent__message">
            <p class="m-0" v-html-safe="$t('cookie_message_text')"></p>
          </div>
        </template>
  </alert-component>
</template>

<script>
import alertComponent from './AlertComponent.vue'
export default {
  name: 'cookieMessage',
  components: {
    alertComponent
  },
  props: ['buttons_enable', 'cookie_message_label', 'is_it_cookie'],
  methods: {
    closeDiv () {
      this.$emit('close-alert-component')
    }
  }
}
</script>

<style scoped>

</style>
